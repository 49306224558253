<template>
  <div style="margin: 20px">
    <el-button style="margin-bottom: 10px" type="success" @click="create">Создать</el-button>
    <el-select v-loading="disableExportBtn" v-model="type" placeholder="Скачать журнал" @change="downloadExport"
               style="float: right">
      <el-option
        v-for="item in exportClassifications"
        :key="item.id"
        :label="item.title"
        :value="item.id">
      </el-option>
    </el-select>
    <v-table v-loading="loading" :fetch="fetchData" name="controlpki" :columns="columns"/>
  </div>
</template>

<script>
import VTable from '@/components/VTable'
import { getPKIList } from '@/api/api'
import { getIogvDict } from '@/api/catalogs'
import { getExportJournal } from '@/api/file'
import { saveFile } from '@/utils'

export default {
  components: {
    VTable
  },
  data () {
    return {
      iogvs: [],
      loading: false,
      disableExportBtn: false,
      filters: null,
      type: null,
      classifications: [
        {
          id: 1,
          title: 'Общий'
        },
        {
          id: 2,
          title: 'Индивидуальный'
        },
        {
          id: 3,
          title: 'КПР КНД'
        }
      ],
      exportClassifications: [
        {
          id: 1,
          title: 'По общим КПР'
        },
        {
          id: 2,
          title: 'По индивидуальным КПР'
        },
        {
          id: 3,
          title: 'По КПР КНД'
        }
      ]
    }
  },
  computed: {
    columns () {
      const classifications = this.classifications
      const iogvs = this.iogvs
      return [
        {
          field: 'valid',
          filter (value) {
            return value ? 'Да' : 'Нет'
          },
          width: '120px',
          items: [
            {
              id: 0,
              title: 'Нет'
            },
            {
              id: 1,
              title: 'Да'
            }
          ]
        },
        {
          field: 'code',
          width: '100px'
        },
        {
          field: 'pki_id',
          width: '100px'
        },
        {
          field: 'classification_id',
          filter (value) {
            return classifications.find(x => x.id === value)?.title
          },
          width: '135px',
          items: classifications,
          multiple: true
        },
        {
          field: 'ebosps',
          items: iogvs,
          multiple: true
        },
        {
          field: 'name',
          width: '300px'
        },
        {
          field: 'rule',
          type: 'formula',
          filterable: false,
          width: '200px'
        }
      ]
    }
  },
  mounted () {
    this.loading = true
    getIogvDict({ is_kp: false }).then((response) => {
      this.iogvs = response.data.data
      this.loading = false
    })
  },
  methods: {
    downloadExport (type) {
      this.disableExportBtn = true
      getExportJournal({ ...this.filters, classification_id: type }).then(response => {
        const fileName = response.headers['content-disposition'].split('filename*=utf-8\'\'')[1].split(';')[0]
        saveFile(response.data, decodeURI(fileName))
      }).finally(() => {
        this.disableExportBtn = false
        this.type = null
      })
    },
    fetchData (params) {
      const data = Object.assign({}, params)
      if (!data.classification_id || data.classification_id?.length === 0) {
        data.classification_id = [1, 2, 3]
      }
      this.filters = data
      return getPKIList(data)
    },
    create () {
      this.$router.push({ name: this.$route.name.replace('list', 'create') })
    }
  }
}
</script>

<style scoped>

</style>
